@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,100;1,300&display=swap");
* {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  -webkit-transition: all .2s linear;
  transition: all .2s linear;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 6rem;
}

html::-webkit-scrollbar {
  width: 1rem;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

html::-webkit-scrollbar-thumb {
  background: #0CB8A5;
}

section {
  padding: 5rem 9%;
}

.randingZone {
  position:absolute;
  height:100%;
  width:100%;
  overflow: hidden;
}

.randingZone video {
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.randingButton {
  cursor: pointer;
  color: white;
  position: fixed;
  font-weight: 700;
  font-size: 10rem;
  bottom: 15%;
  left: 15%;
  z-index: 2;
}

.btn {
  display: inline-block;
  margin-top: 1rem;
  border: 0.2rem solid #222;
  background: #fff;
  padding: 1rem 3rem;
  font-size: 1.7rem;
  cursor: pointer;
  color: #222;
}

.btn:hover {
  color: #fff;
  background: #0CB8A5;
}

.heading {
  text-align: center;
  margin-bottom: 4rem;
}

.heading h1 {
  font-size: 6.5rem;
  color: #222;
}

.heading p {
  margin: 1rem auto;
  font-size: 1.5rem;
  color: #777;
  max-width: 50rem;
  line-height: 2;
}
.__react_modal_image__header {
  background-color : #0CB8A5 !important;
  min-height: 6vh !important;
  padding: 1% !important;
}

.__react_modal_image__medium_img {
  max-width: 75% !important;
  max-height: 75% !important;
  background-color :rgba(0, 0, 0, 0.0) !important;
}

@-webkit-keyframes fadeUp {
  0% {
    -webkit-transform: translateY(4rem);
            transform: translateY(4rem);
    opacity: 0;
  }
}

@keyframes fadeUp {
  0% {
    -webkit-transform: translateY(4rem);
            transform: translateY(4rem);
    opacity: 0;
  }
}

.developer {
  background: linear-gradient(to right, #fbcac9, #8ca6ce);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.header {
  padding: 1.5rem 9%;
  background: #fff;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header .logo img {
  height: 6rem;
}

.header .search-form {
  height: 5rem;
  width: 50rem;
  border-radius: .5rem;
  background: #f5f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 1.5rem;
}

.header .search-form input {
  width: 100%;
  background: none;
  font-size: 1.6rem;
  color: #777;
  text-transform: none;
}

.header .search-form label {
  font-size: 2rem;
  margin-left: .7rem;
  color: #222;
  cursor: pointer;
}

.header .search-form label:hover {
  color: #0CB8A5;
}

.header .icons a,
.header .icons div {
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
  background: #f5f5f5;
  color: #222;
  cursor: pointer;
  font-size: 1.8rem;
  margin-left: .3rem;
  border-radius: .5rem;
}

.header .icons a:hover,
.header .icons div:hover {
  background: #0CB8A5;
  color: #fff;
}

.header #search-btn {
  display: none;
}

.navbar {
  position: fixed;
  top: 0;
  left: -110%;
  z-index: 1010;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding-right: 15%;
}

.navbar.active {
  left: 0;
}

.navbar #close-navbar {
  position: absolute;
  top: 3rem;
  right: 3rem;
  height: 5rem;
  width: 5rem;
  line-height: 5rem;
  text-align: center;
  background: #fff;
  color: #222;
  cursor: pointer;
  font-size: 3rem;
}

.navbar #close-navbar:hover {
  color: #fff;
  background: #0CB8A5;
}

.navbar a {
  font-size: 4rem;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}

.navbar a:hover {
  color: #0CB8A5;
  letter-spacing: .2rem;
}

.home {
  padding: 0;
}

.home .slide {
  display: none;
  height: calc(100vh - 8rem);
}

.home .slide.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.home .slide .content {
  width: 40%;
  padding: 2rem 7%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  background: #ffffff;
  position: relative;
}

.home .slide .content img {
  height: 25rem;
  width: 25rem;
  margin-bottom: 2rem;
  -webkit-animation: fadeUp .2s linear backwards .2s;
          animation: fadeUp .2s linear backwards .2s;
  display: none;
}

.home .slide .content span {
  color: #0CB8A5;
  display: block;
  font-size: 1.8rem;
  padding-bottom: .5rem;
  letter-spacing: .2rem;
  text-transform: uppercase;
  -webkit-animation: fadeUp .2s linear backwards .4s;
          animation: fadeUp .2s linear backwards .4s;
}

.home .slide .content h3 {
  font-size: 8rem;
  text-transform: uppercase;
  color: #222;
  -webkit-animation: fadeUp .2s linear backwards .6s;
          animation: fadeUp .2s linear backwards .6s;
}

.home .slide .content .btn {
  -webkit-animation: fadeUp .2s linear backwards .8s;
          animation: fadeUp .2s linear backwards .8s;
}

.home .slide .content .controls {
  position: absolute;
  bottom: 12%;
  right: -7rem;
}

.home .slide .content .controls div {
  height: 5rem;
  width: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 3rem;
  background: #fff;
  color: #222;
  margin: 0 1rem;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.home .slide .content .controls div:hover {
  background: #0CB8A5;
  color: #fff;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.home .slide .image {
  width: 60%;
}

.home .slide .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.category {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(16rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 2rem;
}

.category .box {
  padding: 2rem;
  border-radius: .5rem;
  background: #f5f5f5;
  text-align: center;
}

.category .box:hover {
  background: #0CB8A5;
}

.category .active {
  background: #0CB8A5;
  color: #fff;
}

.category .box:hover img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.category .box:hover p {
  color: #fff;
}

.category .box img {
  height: 10rem;
  margin-bottom: 1rem;
}

.category .box p {
  font-size: 1.7rem;
  color: #222;
}

.about {
  /* background: url(../images/product/BOOK-2.jpg) no-repeat; */
  background-size: cover;
  background-position: center;
  /* background-attachment: fixed; */
  height: 50vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}



.about .content {
  width: 55rem;
  /* background: linear-gradient(217deg, rgba(255,0,0,.8), rgba(255,0,0,0) 70.71%),
  linear-gradient(127deg, rgba(0,255,0,.8), rgba(0,255,0,0) 70.71%),
  linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%); */
  padding: 3rem;
}

.about .content span {
  color: black;
  font-weight: bold;
  font-size: 4rem;
}

.about .content h3 {
  padding-top: 1rem;
  font-size: 4rem;
  color: #222;
}

.about .content p {
  font-size: 1.5rem;
  color: #777;
  line-height: 2;
  padding: 1rem 0;
}

.shop .slide {
  border: 0.2rem solid #222;
  background: #fff;
}

.shop .slide:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.shop .slide:hover .image .icons {
  right: 1rem;
}

.shop .slide .image {
  height: 35rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.shop .slide .image .discount {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: .5rem 1.5rem;
  font-size: 1.5rem;
  color: #fff;
  background: #e84393;
  z-index: 10;
}

.shop .slide .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.shop .slide .image .icons {
  position: absolute;
  bottom: 0;
  right: -8rem;
}

.shop .slide .image .icons a {
  height: 5rem;
  width: 5rem;
  line-height: 4.5rem;
  font-size: 2rem;
  background: #fff;
  color: #222;
  border: 0.2rem solid #222;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  text-align: center;
  display: block;
}

.shop .slide .image .icons a:hover {
  background: #e84393;
  color: #fff;
}

.shop .slide .content {
  padding: 2rem;
  border-top: 0.2rem solid #222;
}

.shop .slide .content .stars {
  padding-bottom: .5rem;
}

.shop .slide .content .stars i {
  font-size: 1.5rem;
  color: #777;
}

.shop .slide .content p {
  font-size: 2rem;
  color: #222;
  line-height: 2;
}

.shop .slide .content .price {
  font-size: 2rem;
  color: #e84393;
}

.shop .slide .content .price span {
  font-size: 1.5rem;
  color: #777;
  text-decoration: line-through;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 2rem;
  color: #222;
}

.gallery {
  background: #f5f5f5;
}

.gallery .lightbox {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(31rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
  gap: 2rem;
  justify-content: center;
  align-items: center;

}

.gallery .lightbox .modal {
  overflow: hidden;
  border: 1rem solid #fff;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}



.gallery .lightbox .modal:hover img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.aboutUs {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(100%, #f5f5f5), color-stop(100.1%, #fff));
  background: linear-gradient(#f5f5f5 100%, #fff 100.1%);
}

.aboutUs .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(31rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
  gap: 2rem;
}

.aboutUs .box-container .box {
  border: 0.2rem solid #222;
  text-align: center;
}

.aboutUs .box-container .box:hover .image img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.aboutUs .box-container .box:hover .image .share {
  left: 0;
}

.aboutUs .box-container .box .image {
  height: 50rem;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.aboutUs .box-container .box .image img {
  height: 100%;
  /* width: 100%; */
  /* -o-object-fit: cover;
     object-fit: cover; */
}

.aboutUs .box-container .box .image .share {
  position: absolute;
  top: 0;
  left: -8rem;
  background: #0CB8A5;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0 1rem;
  gap: 1rem;
}

.aboutUs .box-container .box .image .share a {
  height: 5rem;
  width: 5rem;
  border: 0.2rem solid #fff;
  font-size: 2.5rem;
  color: #fff;
  line-height: 5rem;
}

.aboutUs .box-container .box .image .share a:hover {
  background: #fff;
  color: #222;
}

.aboutUs .box-container .box .content {
  border-top: 0.2rem solid #cccccc;
  padding: 1.5rem;
}

.aboutUs .box-container .box .content h3 {
  font-size: 2rem;
  color: #222;
}

.aboutUs .box-container .box .content p {
  font-size: 1.5rem;
  color: #e84393;
}

.partners .slide {
  text-align: center;
  background: #fff;
}

.partners .slide:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.partners .slide .image {
  height: 30rem;
  width: 100%;
  overflow: hidden;
}

.partners .slide .image img {
  height: 60%;
  width: 60%;
  -o-object-fit: scale-down;
     object-fit: scale-down;
}

.partners .slide .content {
  padding-top: .5rem;
}

.partners .slide .content p {
  font-size: 2rem;
  color: #222;
  line-height: 2;
  padding: .5rem 0;
}

.partners .slide .content .price {
  font-size: 2rem;
  color: #e84393;
}

.partners .slide .content .price span {
  color: #777;
  font-size: 1.5rem;
  text-decoration: line-through;
}

.reviews-slider .slide .user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.reviews-slider .slide .user img {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
}

.reviews-slider .slide .user h3 {
  font-size: 2rem;
  color: #222;
}

.reviews-slider .slide .user .stars i {
  font-size: 1.5rem;
  color: #e84393;
}

.reviews-slider .slide .text {
  padding: 2rem;
  background: #f5f5f5;
  border-radius: .5rem;
  line-height: 2;
  font-size: 1.5rem;
  color: #777;
  position: relative;
  margin-top: 2rem;
}

.reviews-slider .slide .text::before {
  content: '';
  position: absolute;
  top: -1rem;
  left: 3rem;
  height: 2rem;
  width: 2rem;
  background: #f5f5f5;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.service {
  background: #f5f5f5;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(31rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
  gap: 2rem;
}

.service .box {
  border: 0.2rem solid #222;
  padding: 2rem;
  text-align: center;
  background: #fff;
}

.service .box:hover {
  background: #222;
}

.service .box:hover img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.service .box:hover h3 {
  color: #fff;
}

.service .box:hover p {
  color: #fff;
}

.service .box img {
  height: 8rem;
  margin-bottom: .5rem;
}

.service .box h3 {
  padding: .7rem 0;
  font-size: 2rem;
  color: #222;
}

.service .box p {
  font-size: 1.5rem;
  color: #777;
  line-height: 2;
}

.contactUs .slide:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.contactUs .slide:hover .image .icons {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.contactUs .slide .image {
  height: 30rem;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.contactUs .slide .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.contactUs .slide .image .icons {
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transform: translateY(10rem);
          transform: translateY(10rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1.5rem;
  border: 0.2rem solid #222;
  z-index: 10;
}

.contactUs .slide .image .icons a {
  font-size: 1.5rem;
  color: #222;
}

.contactUs .slide .image .icons a i {
  color: #e84393;
  padding-right: .5rem;
}

.contactUs .slide .image .icons a:hover {
  color: #e84393;
}

.contactUs .slide .content {
  text-align: center;
  padding: 1.5rem;
}

.contactUs .slide .content h3 {
  font-size: 2rem;
  color: #222;
}

.contactUs .slide .content p {
  padding: 1rem 0;
  line-height: 2;
  color: #777;
  font-size: 1.5rem;
}

.contactInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactInfo .box{
  background: #0000000f;
  padding: 10%;
}
.footer {
  background: #f5f5f5;
}

.footer .box-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 2rem;
}

.footer .box-container .box h3 {
  font-size: 2.2rem;
  color: #222;
  padding: 1rem 0;
}

.footer .box-container .box .link {
  padding: 1rem 0;
  display: block;
  font-size: 1.5rem;
  color: #777;
}

.footer .box-container .box .link i {
  padding-right: .5rem;
  color: #e84393;
}

.footer .box-container .box .link:hover {
  color: #e84393;
}

.footer .box-container .box .link:hover i {
  padding-right: 2rem;
}

.footer .box-container .box p {
  font-size: 1.5rem;
  color: #777;
  padding: 1rem 0;
}

.footer .box-container .box p i {
  padding-right: .5rem;
  color: #e84393;
}

.footer .box-container .box .share {
  margin-top: 2rem;
}

.footer .box-container .box .share a {
  height: 5rem;
  width: 5rem;
  background: #fff;
  border: 0.2rem solid #222;
  font-size: 2rem;
  line-height: 4.5rem;
  color: #222;
  text-align: center;
  margin-right: .3rem;
}

.footer .box-container .box .share a:hover {
  background: #e84393;
  color: #fff;
}

.footer .box-container .box form .email {
  width: 100%;
  border: 0.2rem solid #222;
  padding: 1.2rem 1.4rem;
  font-size: 1.6rem;
  color: #777;
  text-transform: none;
  margin: 1rem 0;
}

.footer .credit {
  text-align: center;
  font-size: 2rem;
  margin-top: 3rem;
  padding: 1rem;
  border-top: 0.2rem solid #222;
  padding-top: 3rem;
  color: #777;
}

.footer .credit span {
  color: #e84393;
}


/* randing mouse  */

.glow-on-hover {
  width: 220px;
  height: 50px;
  outline: none;
  color: rgb(170, 159, 159);
  font-weight: bold;
  font-size: large;
  background: inherit;
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  bottom: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(20% - 4px);
  animation: glowing 20s linear infinite;
  opacity: 0.4;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}


.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}



@media (max-width: 991px) {
  html {
    font-size: 55%;
  }
  .header {
    padding: 1.5rem 2rem;
  }
  section {
    padding: 3rem 2rem;
  }
  .home .slide .content {
    padding: 2rem;
  }
  .home .slide .content span {
    font-size: 2rem;
  }
  .home .slide .content h3 {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  .header #search-btn {
    display: inline-block;
  }
  .header .search-form {
    position: absolute;
    top: 99%;
    left: 0;
    border-top: 0.2rem solid #222;
    border-bottom: 0.2rem solid #222;
    width: 100%;
    background: #fff;
    height: 6rem;
    display: none;
    -webkit-animation: fadeUp .2s linear backwards;
            animation: fadeUp .2s linear backwards;
  }
  .header .search-form.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .home .slide {
    position: relative;
    height: calc(100vh - 6rem);
  }
  .home .slide .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    height: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .home .slide .content img {
    display: inline-block;
  }
  .home .slide .content .controls {
    position: static;
    margin-top: 7rem;
  }
  .home .slide .content .controls div {
    margin: 0 .5rem;
  }
  .home .slide .image {
    width: 100%;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .__react_modal_image__medium_img {
    max-width: 150% !important;
    max-height: 150% !important;
  }

  .heading h1 {
    font-size: 3.5rem;
    color: #222;
  }
}
/*# sourceMappingURL=style.css.map */